import "./index.css";
import { useEffect, useRef, useState, useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { Base64 } from "js-base64";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { useNavigate } from "react-router-dom";

import { IQueryParams } from "../../types/QueryParams.types";
import { SolanaAction, ENVIRONMENTS, WALLET_TYPE } from "../../enums";
import WalletComponent from "./WalletComponent";

interface ConnectionProviderComponentProps {
  queryParams: IQueryParams;
}

function ConnectionProviderComponent({
  queryParams,
}: ConnectionProviderComponentProps) {
  const { env, walletType } = queryParams;

  const clusterApi = env == ENVIRONMENTS.PRODUCTION ? "mainnet-beta" : "devnet";

  const wallets = useMemo(() => {
    const walletsAdpaters = [];

    if (walletType == WALLET_TYPE.PHANTOM) {
      walletsAdpaters.push(new PhantomWalletAdapter());
    }

    // new SolflareWalletAdapter(),
    // new GlowWalletAdapter(),
    // new MathWalletAdapter(),

    return walletsAdpaters;
  }, []);

  const endpoint = useMemo(() => clusterApiUrl(clusterApi), []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletComponent queryParams={queryParams} />
      </WalletProvider>
    </ConnectionProvider>
  );
}

function Home() {
  // const querySearch = new URLSearchParams(window.location.search);
  // const querySearchParams = querySearch.get("params") ?? "";

  const [isInit, setIsInit] = useState<boolean>(false);
  const querySearchParamsRef = useRef<IQueryParams | null>();

  const navigate = useNavigate();

  const initQueryParams = async () => {
    const querySearch = new URLSearchParams(window.location.search);
    const querySearchParams = querySearch.get("params") ?? "";

    if (querySearchParams && querySearchParams !== "") {
      try {
        const params: IQueryParams = JSON.parse(
          Base64.decode(querySearchParams)
        );
        querySearchParamsRef.current = params;
        setIsInit(true);
      } catch (error) {
        console.error(error);
        navigate("no_page");
      }
    } else if (window.location.hostname === "localhost") {
      const params: IQueryParams = {
        env: ENVIRONMENTS.DEVELOPMENT,
        action: SolanaAction.AUTHENTICATE,
        data: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VJZCI6IjQ4NUM2N0JGLThCQjEtNEYwNS04M0ZFLTZCOTk3MzQzNDI4MyIsImlhdCI6MTcwMDU1MTY2MH0.KZMopbHekDWiV1nB6vE40YyhMQlTa7yJ8WBMN-oFFKs",
        callbackURL: "wagmigame://phantomCallback",
        walletType: WALLET_TYPE.PHANTOM,
      }; // for testing authentication

      // const params = {
      //   env: ENVIRONMENTS.DEVELOPMENT,
      //   action: SolanaAction.BURN_BATCH_NFT,
      //   tokens: [
      //     "DSBTxNpmMRhj8htMcRfAK5cFehXix2SDiK1CPoTkCw4i",
      //     "8QqMGC77oT2DzdsPsvYmHm4r3HDn14TyPunvNPLn3Q3o",
      //   ],
      //   callbackURL: "wagmigame://phantomCallback",
      //   walletType: WALLET_TYPE.PHANTOM,
      // }; // for testing bunch transfer

      querySearchParamsRef.current = params;

      setIsInit(true);
    } else {
      navigate("no_page");
    }

    //setIsInit(true);
  };

  useEffect(() => {
    initQueryParams();
  }, []);

  if (!isInit) {
    return null;
  }

  return (
    <ConnectionProviderComponent queryParams={querySearchParamsRef.current} />
  );
}

export default Home;
