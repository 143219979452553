enum SolanaAction {
  AUTHENTICATE = "authenticate",
  BURN_BATCH_NFT = "burn_batch_nft",
  LOGOUT = "logout",
}

enum ENVIRONMENTS {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

enum WALLET_TYPE {
  PHANTOM = "Phantom",
}

enum COLLECTIONS {
  "development" = "2HPLKeuWMcdX7XhTrvRTMqWyxhWcEmxzuMmtAowezRKA",
  "staging" = "6nmwAqe9NyrcowosSkE13xKHXRY8EJPr3TLUAoik1Mw6",
  "production" = "2HPLKeuWMcdX7XhTrvRTMqWyxhWcEmxzuMmtAowezRKA",
}

export { SolanaAction, ENVIRONMENTS, WALLET_TYPE, COLLECTIONS };
