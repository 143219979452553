import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { publicKey, transactionBuilder } from "@metaplex-foundation/umi";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { mplCore, burnV1 } from "@metaplex-foundation/mpl-core";
import { useEffect, useRef, useState, useMemo } from "react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import md5 from "crypto-js/md5";
import bs58 from "bs58";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";

import { wagmiLogo, walletIcon, solanaIcon } from "../../assets/images";
import { IQueryParams } from "../../types/QueryParams.types";
import { COLLECTIONS, ENVIRONMENTS, SolanaAction } from "../../enums";

interface WalletComponentProps {
  queryParams: IQueryParams;
}

export default function WalletComponent({ queryParams }: WalletComponentProps) {
  const walletProvider = useWallet();

  const { connection } = useConnection();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [walletBalance, setWalletBalance] = useState<string | null>(null);
  const walletBalanceRef = useRef<string | null>(null);

  const phantomWallet = useMemo(
    () =>
      walletProvider.wallets.find(
        (wallet) =>
          wallet.readyState === "Installed" &&
          wallet.adapter.name == queryParams.walletType
      ),
    []
  );

  const navigate = useNavigate();

  const getBlanaceAndInitAction = async () => {
    setIsLoading(true);

    const balance = await connection.getBalance(
      walletProvider.publicKey!,
      "confirmed"
    );

    const balanceSol = (balance / LAMPORTS_PER_SOL).toFixed(2).toString();

    walletBalanceRef.current = balanceSol;

    setWalletBalance(balanceSol);

    setIsLoading(false);

    performAction();
  };

  async function batchBurnNfts() {
    // get query params
    const { env, tokens, action, callbackURL } = queryParams;
    // set rpc end point
    const rpcEndPoint =
      env === ENVIRONMENTS.PRODUCTION
        ? "https://api.mainnet-beta.solana.com"
        : "https://api.devnet.solana.com";
    // Create Umi instance
    const umi = createUmi(rpcEndPoint)
      .use(mplCore())
      .use(walletAdapterIdentity(walletProvider));

    const collectionAddress = COLLECTIONS[env];

    const collection = publicKey(collectionAddress);

    const assets = tokens!;

    console.log(`umi`, umi);

    // set builder
    let builder = transactionBuilder();
    assets.forEach((item) => {
      const assetPublicKey2 = publicKey(item);
      builder = builder.add(
        burnV1(umi, {
          asset: assetPublicKey2,
          collection: collection,
        })
      );
    });
    // check size of transction
    const isSmallEnough = builder.fitsInOneTransaction(umi);
    if (isSmallEnough) {
      // confirm transction
      const result = await builder.sendAndConfirm(umi);
      // get hash
      const signatureBase58 = bs58.encode(result.signature);
      // get wallet address
      const walletAddress = walletProvider.publicKey!.toBase58();
      //console.log(`signatureBase58`, signatureBase58);
      //console.log(`walletAddress`, walletAddress);
      //send to callback
      navigate("callback", {
        state: {
          callbackURL,
          action: action,
          data: Base64.encode(
            JSON.stringify({
              transactionId: signatureBase58,
              tokens,
              walletAddress,
            })
          ),
        },
      });
    } else {
      setError("Solana Transaction Limit Issue.");
    }
  }

  async function signInRequest() {
    // get query params
    const { action, data, callbackURL } = queryParams;

    // get md5 string
    const md5Data = md5(data);
    const md5StringValue = md5Data.toString();

    // get unit8 array
    const encoder = new TextEncoder();
    const uint8Array = encoder.encode(md5StringValue);

    // sing in
    const signInfo = await walletProvider.signMessage!(uint8Array);

    // Convert the signature  Uint8Array to base58 string
    const hash = bs58.encode(signInfo);
    const walletAddress = walletProvider.publicKey!.toBase58();

    navigate("callback", {
      state: {
        callbackURL,
        action: action,
        data: Base64.encode(
          JSON.stringify({
            hash,
            token: data,
            walletAddress,
          })
        ),
      },
    });
  }

  useEffect(() => {
    if (walletProvider?.publicKey) {
      getBlanaceAndInitAction();
    }
  }, [walletProvider.publicKey]);

  const performAction = async () => {
    try {
      setError("");

      const { action } = queryParams;

      console.log("action");

      switch (action) {
        case SolanaAction.AUTHENTICATE: {
          await signInRequest();
          break;
        }
        case SolanaAction.BURN_BATCH_NFT: {
          console.log(`walletBalance`, walletBalance);
          const walletBalanceNumber = parseFloat(
            walletBalanceRef.current ?? "0"
          );
          console.log(`walletBalanceNumber`, walletBalanceNumber);
          if (walletBalanceNumber > 0) {
            await batchBurnNfts();
          } else {
            setError("Insufficient Funds: Not enough funds to cover gas fees.");
          }
          break;
        }
        default: {
          setError("No Action to perform");
        }
      }
    } catch (error) {
      const errorMessage = error?.message ?? "";

      setError(errorMessage);
    }
  };

  const renderHeader = () => {
    const walletAddress = walletProvider?.publicKey
      ? walletProvider.publicKey.toBase58()
      : null;
    const shortAddress = walletAddress
      ? `${walletAddress.slice(0, 6)}...${walletAddress.slice(
          walletAddress.length - 4,
          walletAddress.length
        )}`
      : null;

    return (
      <div className="header">
        <img
          className="logo"
          alt="Wagmi games"
          src={wagmiLogo}
          decoding="async"
          data-nimg="intrinsic"
        />
        {walletAddress && walletBalance && (
          <div className="walletContainer">
            <div className="walletBalance">
              <img className="ethIcon" src={solanaIcon} />
              {walletBalance}
            </div>
            <button
              className="walletAddressButton"
              onClick={async () => {
                await navigator.clipboard.writeText(walletAddress);
                alert("Copied the text: " + walletAddress);
              }}
            >
              <img className="walletIcon" src={walletIcon} />
              <div className="wallet">{shortAddress}</div>
            </button>
          </div>
        )}
      </div>
    );
  };

  function connectToSolanaButton() {
    return (
      <div className="dialog">
        <h1 className="dialog-title">Connect Wallet</h1>
        <button
          type="button"
          onClick={() => {
            walletProvider.select(phantomWallet!.adapter.name);
          }}
        >
          Connect
        </button>
      </div>
    );
  }

  const renderLoader = () => {
    return (
      <>
        <div className="info-content">
          <div className="loader"></div>
        </div>
      </>
    );
  };

  const renderWaiting = () => {
    return (
      <>
        <div className="info-content">
          <h1>Please Wait...</h1>
        </div>
      </>
    );
  };

  const phantomExtensionError = () => {
    return (
      <div className="dialog">
        <h1 className="dialog-title">Phantom Extension</h1>
        <div className="error">Please add Phantom extension</div>
      </div>
    );
  };

  const renderRetryView = () => {
    return (
      <div className="dialog">
        <h1 className="dialog-title">Please try again</h1>
        <button type="button" onClick={performAction}>
          Retry
        </button>
        {error && <div className="error">{error}</div>}
      </div>
    );
  };

  function renderContent() {
    if (!phantomWallet) {
      return phantomExtensionError();
    }

    if (isLoading) {
      return renderLoader();
    }
    if (!walletProvider.publicKey) {
      return connectToSolanaButton();
    }
    if (error) {
      return renderRetryView();
    }

    return renderWaiting();
  }

  return (
    <div className="container">
      {renderHeader()}
      <div className="content">{renderContent()}</div>
    </div>
  );
}
